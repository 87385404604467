<!-- fieldAwesome.vue -->
<template>
  <div>
    <VueMultiselect
      :class="schema.styleClasses"
      v-model="value"
      :options="schema.values ?? []"
      :multiple="schema.multiple ?? false"
      :close-on-select="true"
      placeholder="Pick some"
      :label="schema.selectOptions.name"
      :track-by="schema.selectOptions.value"
      :limit="schema.limit ?? 9999"
    >
    </VueMultiselect>
  </div>
</template>
  
<script>
import { abstractField } from 'vfg-next'
import VueMultiselect from 'vue-multiselect'

export default {
  mixins: [abstractField],
  components: { VueMultiselect },
  data() {
    return {
      selected: null,
      options: ['list', 'of', 'options']
    }
  }
}
</script>
  
<style src="vue-multiselect/dist/vue-multiselect.css"></style>