const controllerName = 'Organisations'

function Get(id) {
  return window.axios.get(`api/${controllerName}/${id}`)
}

function GetAll(params) {
  return window.axios.get(`api/${controllerName}`, { params })
}

function Register(formModel) {
  return window.axios.post(`api/${controllerName}`, formModel)
}

function Put(id, formModel) {
  return window.axios.put(`api/${controllerName}/${id}`, formModel)
}

function KeyValue() {
  return window.axios.get(`api/${controllerName}/get/keyvalue`)
}

function Autocomplete(search) {
  return window.axios.get(
    `api/${controllerName}/get/auto-complete?search=${encodeURIComponent(
      search
    )}`
  )
}

function DeleteOrganisation(id, isHardDelete) {
  return window.axios.delete(`api/${controllerName}/${id}/${isHardDelete}`)
}

function GetOrganisationAggregates(id) {
  return window.axios.get(`api/${controllerName}/get/organisation-aggregates/${id}`)
}

export { Get, GetAll, Register, Put, KeyValue, Autocomplete, DeleteOrganisation, GetOrganisationAggregates }
