import { IsValidProp } from '../helper.service'

const controllerName = 'documentTypes'

function Get(id) {
  return window.axios.get(`api/${controllerName}/${id}`)
}

function RegisterDocumentType(formModel) {
  return window.axios.post(`api/${controllerName}`, formModel)
}

function PutDocumentType(id, formModel) {
  return window.axios.put(`api/${controllerName}/${id}`, formModel)
}

function DeleteDocumentType(id, isHardDelete) {
  return window.axios.delete(`api/${controllerName}/${id}/${isHardDelete}`)
}

function KeyValue() {
  return window.axios.get(`api/${controllerName}/get/keyvalue`)
}

export {
  Get,
  RegisterDocumentType,
  PutDocumentType,
  DeleteDocumentType,
  KeyValue
}
