import store from '@/store/index.js'
import { CHANGE_SHOW_ACTION_AS_ICON_STATE } from '@/store/auth.module'
const controllerName = 'users'

function Get(id) {
  return window.axios.get(`api/${controllerName}/${id}`)
}

function GetOrganisationUserKeyValue(orgId, params) {
  return window.axios.get(`api/${controllerName}/organisation/${orgId}`, {
    params
  })
}

function RegisterAdmin(formModel) {
  return window.axios.post('api/users', formModel)
}

function PutAdmin(id, formModel) {
  return window.axios.put(`api/users/${id}`, formModel)
}

function PutCustomer(id, formModel) {
  return window.axios.put(`api/users/customer/${id}`, formModel)
}

function PutCustomerOwnAccount(id, formModel) {
  return window.axios.put(`api/users/customer/${id}`, formModel)
}

function PutCustomerOwnPassword(id, formModel) {
  return window.axios.put(`api/users/customer/${id}/password`, formModel)
}

function ChangePasswordAdmin(formModel) {
  return window.axios.post('api/users/change-password', formModel)
}

function ChangeFirstTimePassword(formModel) {
  return window.axios.post('api/users/change-first-time-password', formModel)
}

function RegisterCustomer(formModel) {
  return window.axios.post('api/users/customer', formModel)
}

function DeleteUser(id, isHardDelete) {
  return window.axios.delete(`api/users/${id}}/${isHardDelete}`)
}

function CustomerForgotPassword(formModel) {
  return window.axios.post('api/users/forgot-password', formModel)
}

function ResetPassword(formModel) {
  return window.axios.post('api/users/reset-password', formModel)
}

function ActivateAccount(formModel) {
  return window.axios.post('api/users/activate-account', formModel)
}

function UpdateSettingIsShowActionAsIcon(formModel) {
  return store.dispatch(CHANGE_SHOW_ACTION_AS_ICON_STATE, formModel)
}

function GetSalesmanKeyValue(orgId) {
  return window.axios.get(`api/${controllerName}/salesmen/${orgId}`)
}

export {
  Get,
  GetOrganisationUserKeyValue,
  RegisterAdmin,
  PutAdmin,
  PutCustomer,
  PutCustomerOwnAccount,
  PutCustomerOwnPassword,
  RegisterCustomer,
  ChangePasswordAdmin,
  DeleteUser,
  CustomerForgotPassword,
  ResetPassword,
  ActivateAccount,
  UpdateSettingIsShowActionAsIcon,
  ChangeFirstTimePassword,
  GetSalesmanKeyValue
}
