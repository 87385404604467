
const controllerName = 'dataTypes'

function Get(id) {
  return window.axios.get(`api/${controllerName}/${id}`)
}
function KeyValue() {
  return window.axios.get(`api/${controllerName}/get/keyvalue`)
}

export { Get, KeyValue }
