<!-- fieldAwesome.vue -->
<template>
  <Datepicker
    autoApply
    textInput
    :readonly="schema.readonly"
    v-model="value"
    :disabled="disabled"
    :placeholder="usedFormat"
    :format="usedFormat"
    :enableTimePicker="false"
    :min-date="minDate"
    :max-date="maxDate"
    :class="schema.styleClasses"
  />
  <button class="btn btn-outline-secondary date_picker_button" v-if="!disabled" @click="value = null">Clear</button>
</template>

<script>
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { abstractField } from 'vfg-next'

export default {
  computed: {
    minDate() {
      return this.schema.minDate ?? null
    },
    maxDate() {
      return this.schema.maxDate ?? null
    },
    usedFormat() {
      return this.schema.format ?? 'dd/MM/yyyy'
    }
  },
  components: {
    Datepicker: Datepicker
  },
  mixins: [abstractField]
}
</script>
