<!-- fieldAwesome.vue -->
<template>
  <SimpleTypeahead
    ref="typeahead"
    class="form-control"
    placeholder="Start writing..."
    :disabled="disabled"
    :items="items"
    :defaultItem="defaultItem"
    :minInputLength="3"
    :itemProjection="
      item => {
        return item.value
      }
    "
    @selectItem="selectItem"
    @onInput="onInput"
    :style="addedStyle"
  >
  </SimpleTypeahead>
</template>

<script>
import SimpleTypeahead from 'vue3-simple-typeahead'
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css' //Optional default CSS
import { abstractField } from 'vfg-next'

export default {
  data() {
    return {
      items: [],
      defaultItem: null,
      addedStyle: ''
    }
  },
  methods: {
    async selectItem(item) {
      this.value = item.key
      if(this.schema.dependentEvent)
      {
        await this.schema.dependentEvent(item)
      }
    },
    async onInput(event) {
      if (event.input.length >= 3) {
        const response = await this.schema.fetch(event.input)
        this.items = response.data.data
      }
      if (event.input.length == 0) {
        this.value = null
      }
    }
  },
  async mounted() {
    if (this.schema.dependentEvent)
    {
      this.defaultItem = await this.schema.setDefaultItems()
      if (this.defaultItem)
      {
        this.items = [this.defaultItem]
        this.$refs.typeahead.input = this.defaultItem.value
      }
    }

    if (this.schema.addedStyle)
    {
      this.addedStyle = this.schema.addedStyle
    }
  },
  components: {
    SimpleTypeahead: SimpleTypeahead
  },
  mixins: [abstractField]
}
</script>
