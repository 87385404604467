<!-- fieldAwesome.vue -->
<template>
    <div :id="schema.target" style="width:100%"></div>
</template>
  
<script>
import { abstractField } from 'vfg-next'

export default {
    mixins: [abstractField]
}
</script>
  