import Role from '@/core/constant/role'

const state = () => {
  return {
    menu: [
      {
        icon: 'BoxIcon',
        pageName: 'customer-portal',
        title: 'Customer Portal',
        subMenu: [
          // {
          //   icon: 'BriefcaseIcon',
          //   pageName: 'organisations',
          //   title: 'Organisations'
          // },
          {
            icon: 'UserIcon',
            pageName: 'admins',
            title: 'Users'
          },
          // {
          //   icon: 'BookOpenIcon',
          //   pageName: 'documentTypes',
          //   title: 'Document Types'
          // },
          {
            icon: 'CheckIcon',
            pageName: 'projects',
            title: 'Projects'
          },
          {
            icon: 'FolderPlusIcon',
            pageName: 'dataRequests',
            title: 'Data Requests'
          }
        ]
      }
    ]
  }
}

// getters
const getters = {
  // menu: state => state.menu,
  menu(state, getters, rootState, rootGetters) {
    const menus = JSON.parse(JSON.stringify(state.menu))
    if (
      rootGetters.currentPermissions.includes('DataRequestTemplate-Read')
    ) {
      menus[0].subMenu.splice(3, 0, {
        icon: 'BriefcaseIcon',
        pageName: 'dataRequestTemplates',
        title: 'Data Request Templates'
      })
    }
    if (
      rootGetters.currentPermissions.includes('Organisation-Read')
    ) {
      menus[0].subMenu.splice(0, 0, {
        icon: 'BriefcaseIcon',
        pageName: 'organisations',
        title: 'Organisations'
      })
    }
    if (
      rootGetters.currentPermissions.includes('Settings-Read')
    ) {
      menus[0].subMenu.splice(0, 0, {
        icon: 'UserIcon',
        pageName: 'adminEmailTemplates',
        title: 'Email Templates'
      })

      menus[0].subMenu.splice(2, 0, {
        icon: 'BookOpenIcon',
        pageName: 'documentTypes',
        title: 'Document Types'
      })

      menus.push({
        icon: 'BoxIcon',
        pageName: 'integration',
        title: 'Integration',
        subMenu: [
          {
            icon: 'BriefcaseIcon',
            pageName: 'typeformNdaRequest',
            title: 'Typeform NDA Request'
          }
        ]
      })

      menus.push({
        icon: 'BoxIcon',
        pageName: 'data-ecosystem',
        title: 'Data Ecosystem',
        subMenu: [
          {
            icon: 'BriefcaseIcon',
            pageName: 'ecosystemMasters',
            title: 'Masters'
          },
          {
            icon: 'BriefcaseIcon',
            pageName: 'ecosystemImportLogs',
            title: 'Import Logs'
          }
        ]
      })

      menus.push({
        icon: 'BoxIcon',
        pageName: 'help-setting',
        title: 'Help Settings',
        subMenu: [
          {
            icon: 'BriefcaseIcon',
            pageName: 'helpsections',
            title: 'Section'
          },
          {
            icon: 'BriefcaseIcon',
            pageName: 'helplinks',
            title: 'Link'
          }
        ]
      })

      menus.push({
        icon: 'BoxIcon',
        pageName: 'setting',
        title: 'Settings',
        subMenu: [
          {
            icon: 'UserIcon',
            pageName: 'adminNdaDocusignSettings',
            title: 'Nda Docusign Settings'
          },
          {
            icon: 'DatabaseIcon',
            pageName: 'adminMySqlConfigurations',
            title: 'MySQL Configurations'
          },
          {
            icon: 'ShuffleIcon',
            pageName: 'adminPipedriveFields',
            title: 'Pipedrive Field Mapping'
          }
        ]
      })
    }
    if (
      rootGetters.currentPermissions.includes('Endole-Read') ||
      rootGetters.currentPermissions.includes('ScrapEndoleMapping-Read')
    ) {
      menus.push({
        icon: 'BoxIcon',
        pageName: 'tool',
        title: 'Tools',
        subMenu: [
          {
            icon: 'UserIcon',
            pageName: 'endole',
            title: 'Endole'
          },
          {
            icon: 'UserIcon',
            pageName: 'scrapEndoleMapping',
            title: 'Scrap Endole Mapping'
          },
          {
            icon: 'UserIcon',
            pageName: 'scrapEndole',
            title: 'Scrap Endole'
          }
        ]
      })
    }

    menus.push({
      icon: 'TrelloIcon',
      pageName: 'helpArea',
      title: 'Help'
    })
    if (
      rootGetters.currentRoles.includes('SuperAdmin') &&
      rootGetters.isDisplayAppSettings
    ) {
      menus.push({
        icon: 'TrelloIcon',
        pageName: 'checkAppSettings',
        title: 'Check App Settings'
      })
    }

    if (
      rootGetters.currentRoles.every(e => e === 'Salesman')
    ) {
      menus[0].subMenu.splice(0, 1)
    }

    
    

    


    return menus
  }
}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

// {
//   icon: 'HomeIcon',
//   pageName: 'side-menu-dashboard',
//   title: 'Dashboard',
//   subMenu: [
//     {
//       icon: '',
//       pageName: 'side-menu-dashboard-overview-1',
//       title: 'Overview 1'
//     },
//     {
//       icon: '',
//       pageName: 'side-menu-dashboard-overview-2',
//       title: 'Overview 2'
//     },
//     {
//       icon: '',
//       pageName: 'side-menu-dashboard-overview-3',
//       title: 'Overview 3'
//     }
//   ]
// },
