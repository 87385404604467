import {
  fieldCheckbox,
  fieldChecklist,
  fieldInput,
  fieldLabel,
  fieldRadios,
  fieldSelect,
  fieldSubmit,
  fieldTextArea,
  fieldUpload,
  fieldVueMultiSelect
} from 'vfg-next'

import fieldDatepicker from '@/components/.vfg/fieldDatepicker.vue'

export default {
  fieldCheckbox,
  fieldChecklist,
  fieldInput,
  fieldLabel,
  fieldRadios,
  fieldSelect,
  fieldSubmit,
  fieldTextArea,
  fieldUpload,
  fieldVueMultiSelect,
  fieldDatepicker
}
