import { KeyValue as getOrganisations } from '@/core/services/entities/organisations.service'
import { KeyValue as getDocumentTypes } from '@/core/services/entities/documentTypes.service'
import { 
  KeyValue as getRequestTypes,
  GetAll as getAllRequestTypes
} from '@/core/services/entities/requestTypes.service'
import { KeyValue as getDataTypes } from '@/core/services/entities/dataTypes.service'
import { KeyValue as getOrganisationSizes } from '@/core/services/entities/ecosystemMasterOrganisationSize.service'
import { KeyValue as getOrganisationSicCodeSection } from '@/core/services/entities/ecosystemMasterOrganisationSicCodeSection.service'

import { KeyValue as getOrganisationSicCode } from '@/core/services/entities/ecosystemMasterOrganisationSicCode.service'
import { KeyValue as getOrganisationOperatingAreas } from '@/core/services/entities/ecosystemMasterOrganisationOperatingArea.service'
import { KeyValue as getOrganisationSalesChannels } from '@/core/services/entities/ecosystemMasterOrganisationSalesChannel.service'
import { KeyValue as getOriginSourceTypes } from '@/core/services/entities/ecosystemMasterOriginSourceType.service'
import { KeyValue as getSourceDetails } from '@/core/services/entities/ecosystemMasterOriginSourceDetail.service'
import { KeyValue as getOrganisationInterests } from '@/core/services/entities/ecosystemMasterOrganisationInterest.service'
import Role from '@/core/constant/role'

export const REFRESH_ALL = 'RefreshAllMaster'
export const SET_ORGANISATIONS = 'RefreshOrganisations'
export const SET_DOCUMENTTYPES = 'RefreshDocumentTypes'
export const SET_REQUESTTYPES = 'RefreshRequestTypes'
export const SET_DATATYPES = 'RefreshDataTypes'
export const SET_ORGANISATION_SICCODESECTION =
  'RefreshOrganisationSicCodeSection'
export const SET_ORGANISATIONSIZES = 'RefreshOrganisationSizes'
export const SET_ORGANISATION_SICCODE = 'RefreshOrganisationSicCode'
export const SET_ORGANISATIONITERESTS = 'RefreshOrganisationInterests'
export const SET_ORGANISATIONOPERATINGAREAS =
  'RefreshOrganisationOperatingAreas'
export const SET_ORGANISATIONSALESCHANNELS = 'RefreshOrganisationSalesChannels'
export const SET_ORIGINSOURCETYPES = 'RefreshOriginSourceTypes'
export const SET_ORGANISATION_SOURCEDETAIL = 'RefreshOriganisationSourceDetail'
export const SET_ALL_REQUESTTYPES = 'RefreshAllRequestTypes'

const state = {
  organisations: [],
  documentTypes: [],
  requestTypes: [],
  dataTypes: [],
  organisationSizes: [],
  organisationOperatingAreas: [],
  organisationSalesChannels: [],
  originSourceTypes: [],
  organisationSourceDetails: [],
  organisationInterests: [],
  organisationSicCodeSection: [],
  organisationSicCode: [],
  allRequestTypes: []
}

const mutations = {
  [SET_ORGANISATIONS](state, data) {
    state.organisations = data
  },
  [SET_DOCUMENTTYPES](state, data) {
    state.documentTypes = data
  },
  [SET_REQUESTTYPES](state, data) {
    state.requestTypes = data
  },
  [SET_DATATYPES](state, data) {
    state.dataTypes = data
  },
  [SET_ORGANISATIONSIZES](state, data) {
    state.organisationSizes = data
  },
  [SET_ORGANISATIONOPERATINGAREAS](state, data) {
    state.organisationOperatingAreas = data
  },
  [SET_ORGANISATIONSALESCHANNELS](state, data) {
    state.organisationSalesChannels = data
  },
  [SET_ORIGINSOURCETYPES](state, data) {
    state.originSourceTypes = data
  },
  [SET_ORGANISATION_SOURCEDETAIL](state, data) {
    state.organisationSourceDetails = data
  },
  [SET_ORGANISATIONITERESTS](state, data) {
    state.organisationInterests = data
  },
  [SET_ORGANISATION_SICCODESECTION](state, data) {
    state.organisationSicCodeSection = data
  },
  [SET_ORGANISATION_SICCODE](state, data) {
    state.organisationSicCode = data
  },
  [SET_ALL_REQUESTTYPES](state, data) {
    state.allRequestTypes = data
  }
}

const actions = {
  async [SET_ORGANISATIONS]({ commit }) {
    const response = await getOrganisations()
    if (response.status === 200) {
      commit(SET_ORGANISATIONS, response.data.data)
    }
  },
  async [SET_DOCUMENTTYPES]({ commit }) {
    const response = await getDocumentTypes()
    if (response.status === 200) {
      commit(SET_DOCUMENTTYPES, response.data.data)
    }
  },
  async [SET_REQUESTTYPES]({ commit }) {
    const response = await getRequestTypes()
    if (response.status === 200) {
      commit(SET_REQUESTTYPES, response.data.data)
    }
  },
  async [SET_DATATYPES]({ commit }) {
    const response = await getDataTypes()
    if (response.status === 200) {
      commit(SET_DATATYPES, response.data.data)
    }
  },
  async [SET_ORGANISATIONSIZES]({ commit }) {
    const response = await getOrganisationSizes()
    if (response.status === 200) {
      commit(SET_ORGANISATIONSIZES, response.data.data)
    }
  },
  async [SET_ORGANISATIONOPERATINGAREAS]({ commit }) {
    const response = await getOrganisationOperatingAreas()
    if (response.status === 200) {
      commit(SET_ORGANISATIONOPERATINGAREAS, response.data.data)
    }
  },
  async [SET_ORGANISATIONSALESCHANNELS]({ commit }) {
    const response = await getOrganisationSalesChannels()
    if (response.status === 200) {
      commit(SET_ORGANISATIONSALESCHANNELS, response.data.data)
    }
  },
  async [SET_ORIGINSOURCETYPES]({ commit }) {
    const response = await getOriginSourceTypes()
    if (response.status === 200) {
      commit(SET_ORIGINSOURCETYPES, response.data.data)
    }
  },
  async [SET_ORGANISATIONITERESTS]({ commit }) {
    const response = await getOrganisationInterests()
    if (response.status === 200) {
      commit(SET_ORGANISATIONITERESTS, response.data.data)
    }
  },
  async [SET_ORGANISATION_SICCODESECTION]({ commit }) {
    const response = await getOrganisationSicCodeSection()
    if (response.status === 200) {
      commit(SET_ORGANISATION_SICCODESECTION, response.data.data)
    }
  },
  async [SET_ORGANISATION_SOURCEDETAIL]({ commit }) {
    const response = await getSourceDetails()
    if (response.status === 200) {
      commit(SET_ORGANISATION_SOURCEDETAIL, response.data.data)
    }
  },
  async [SET_ORIGINSOURCETYPES]({ commit }) {
    const response = await getOriginSourceTypes()
    if (response.status === 200) {
      commit(SET_ORIGINSOURCETYPES, response.data.data)
    }
  },
  async [SET_ORGANISATIONITERESTS]({ commit }) {
    const response = await getOrganisationInterests()
    if (response.status === 200) {
      commit(SET_ORGANISATIONITERESTS, response.data.data)
    }
  },
  async [SET_ORGANISATION_SICCODESECTION]({ commit }) {
    const response = await getOrganisationSicCodeSection()
    if (response.status === 200) {
      commit(SET_ORGANISATION_SICCODESECTION, response.data.data)
    }
  },
  async [SET_ORGANISATION_SICCODE]({ commit }) {
    const response = await getOrganisationSicCode()
    if (response.status === 200) {
      commit(SET_ORGANISATION_SICCODE, response.data.data)
    }
  },
  async [SET_ALL_REQUESTTYPES]({ commit }) {
    const response = await getAllRequestTypes()
    if (response.status === 200) {
      const requestTypeData = response.data.data
      let allRequestTypeKeyValues = []
      
      requestTypeData.forEach(item => {
        allRequestTypeKeyValues.push({
          key: item.id,
          value: item.name
        })
      })

      commit(SET_ALL_REQUESTTYPES, allRequestTypeKeyValues)
    }
  },
  [REFRESH_ALL]({ dispatch }) {
    Promise.allSettled([
      dispatch(SET_ORGANISATIONS),
      dispatch(SET_DOCUMENTTYPES),
      dispatch(SET_REQUESTTYPES),
      dispatch(SET_DATATYPES),
      dispatch(SET_ORGANISATION_SICCODESECTION),
      dispatch(SET_ORGANISATION_SICCODE),
      dispatch(SET_ORGANISATIONSIZES),
      dispatch(SET_ORGANISATIONITERESTS),
      dispatch(SET_ORGANISATIONOPERATINGAREAS),
      dispatch(SET_ORGANISATIONSALESCHANNELS),
      dispatch(SET_ORIGINSOURCETYPES),
      dispatch(SET_ORGANISATION_SOURCEDETAIL),
      dispatch(SET_ALL_REQUESTTYPES)
    ])
  }
}

const getters = {
  organisations(state) {
    return state.organisations
  },
  documentTypes(state) {
    return state.documentTypes
  },
  requestTypes(state) {
    return state.requestTypes
  },
  dataTypes(state) {
    return state.dataTypes
  },
  roles() {
    return [
      { id: Role.SystemAdmin, name: 'System Admin' },
      { id: Role.ClientSuperUser, name: 'Customer Super Admin' },
      { id: Role.CustomerAdmin, name: 'Client Admin' },
      { id: Role.ClientUser, name: 'Client User' },
      { id: Role.Approver, name: 'Approver' },
      { id: Role.SuperAdmin, name: 'Super Admin' },
      { id: Role.Salesman, name: 'Salesman' }
    ]
  },
  roleIds() {
    return [
      { id: 1, name: 'Super Admin', isInternal: 'true' },
      { id: 2, name: 'System Admin', isInternal: 'true' },
      { id: 6, name: 'Client Super User', isInternal: 'false' },
      { id: 3, name: 'Customer Admin', isInternal: 'false' },
      { id: 4, name: 'Client User', isInternal: 'false' },
      { id: 5, name: 'Approver', isInternal: 'false' },
      { id: 7, name: 'Salesman', isInternal: 'true' }
    ]
  },
  ecosystemMasterMenus() {
    return [
      { key: 'organisationSize', name: 'Organisation Sizes' },
      {
        key: 'organisationOperatingArea',
        name: 'Organisation Operating Areas'
      },
      { key: 'organisationSalesChannel', name: 'Organisation Sales Channels' },
      { key: 'organisationInterest', name: 'Organisation Interests' },
      { key: 'organisationSicCode', name: 'Organisation Sic Codes' },
      {
        key: 'organisationSicCodeSection',
        name: 'Organisation Sic Code Sections'
      },
      { key: 'originSourceType', name: 'Origin Source Types' },
      { key: 'originSourceDetail', name: 'Origin Source Details' }
    ]
  },
  organisationSizes(state) {
    return state.organisationSizes
  },
  organisationOperatingAreas(state) {
    return state.organisationOperatingAreas
  },
  organisationSalesChannels(state) {
    return state.organisationSalesChannels
  },
  organisationInterests(state) {
    return state.organisationInterests
  },
  organisationSicCodeSection(state) {
    return state.organisationSicCodeSection
  },
  organisationSicCode: state => id => {
    return state.organisationSicCode.filter(x => x.parentId == id)
  },
  originSourceTypes(state) {
    return state.originSourceTypes
  },
  organisationSourceDetails: state => id => {
    return state.organisationSourceDetails.filter(x => x.parentId == id)
  },
  allRequestTypes(state) {
    return state.allRequestTypes
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
