const controllerName = 'EcosystemMasterOrganisationSizes'

function Get(id) {
  return window.axios.get(`api/${controllerName}/${id}`)
}

function GetAll(params) {
  return window.axios.get(`api/${controllerName}`, { params })
}

function Register(formModel) {
  return window.axios.post(`api/${controllerName}`, formModel)
}

function Put(id, formModel) {
  return window.axios.put(`api/${controllerName}/${id}`, formModel)
}

function KeyValue() {
  return window.axios.get(`api/${controllerName}/get/keyvalue`)
}

export { Get, GetAll, Register, Put, KeyValue }
