import { createRouter, createWebHistory } from 'vue-router'
import BaseParent from '@/layouts/BaseParent.vue'
import Login from '../views/login/Main.vue'
import Role from '@/core/constant/role'

const routes = [
  {
    path: '/',
    component: () => import('@/layouts/side-menu/Main.vue'),
    children: [
      {
        path: '',
        name: 'adminDashboard',
        component: () => import('@/views/dashboard/Main.vue'),
        redirect: '/dataRequests'
      },
      {
        path: 'admins',
        name: 'baseAdmins',
        component: BaseParent,
        children: [
          {
            path: '',
            name: 'admins',
            component: () => import('@/views/.admin/Admins/Index.vue'),
            meta: {
              isAllowAll: false,
              role: [
                Role.SystemAdmin,
                Role.SuperAdmin,
                Role.CustomerAdmin,
                Role.ClientUser,
                Role.ClientSuperUser,
                Role.Approver
              ],
              allowDelete: [
                Role.SystemAdmin,
                Role.SuperAdmin,
                Role.CustomerAdmin,
                Role.ClientSuperUser
              ],
              permission: 'User-Read',
              allowAddPermission: 'User-Create',
              allowEditPermission: 'User-Update',
              allowDeletePermission: 'User-Delete'
            }
          }
        ]
      },
      {
        name: 'organisations',
        path: 'organisations',
        component: () => import('@/views/Organisations/Index.vue'),
        meta: {
          isAllowAll: false,
          role: [Role.SystemAdmin, Role.SuperAdmin],
          permission: 'Organisation-Read'
        }
      },
      {
        path: 'adminEmailTemplates',
        name: 'baseEmailTemplates',
        component: BaseParent,
        children: [
          {
            path: '',
            name: 'adminEmailTemplates',
            component: () => import('@/views/EmailTemplates/Index.vue'),
            meta: {
              isAllowAll: false,
              role: [Role.SystemAdmin, Role.SuperAdmin],
              permission: 'Settings-Read'
            }
          },
          {
            path: 'view/:id',
            name: 'adminViewEmailTemplate',
            props: true,
            component: () => import('@/views/EmailTemplates/View.vue'),
            meta: {
              isAllowAll: false,
              role: [Role.SystemAdmin, Role.SuperAdmin],
              permission: 'Settings-Read'
            }
          }
        ]
      },
      {
        name: 'documentTypes',
        path: 'documentTypes',
        component: () => import('@/views/DocumentTypes/Index.vue'),
        meta: {
          isAllowAll: false,
          role: [Role.SystemAdmin, Role.SuperAdmin],
          permission: 'Settings-Read'
        }
      },
      {
        path: 'projects',
        name: 'baseProjects',
        component: BaseParent,
        children: [
          {
            path: '',
            name: 'projects',
            component: () => import('@/views/Projects/Index.vue'),
            meta: {
              isAllowAll: false,
              role: [
                Role.SystemAdmin,
                Role.SuperAdmin,
                Role.CustomerAdmin,
                Role.ClientUser,
                Role.ClientSuperUser,
                Role.Approver
              ],
              allowAdd: [
                Role.SystemAdmin,
                Role.SuperAdmin,
                Role.ClientSuperUser
              ],
              allowEdit: [
                Role.SystemAdmin,
                Role.SuperAdmin,
                Role.ClientSuperUser
              ],
              allowDelete: [
                Role.SystemAdmin,
                Role.SuperAdmin,
                Role.ClientSuperUser
              ],
              allowPrint: [
                Role.SystemAdmin,
                Role.SuperAdmin,
                Role.ClientSuperUser,
                Role.CustomerAdmin
              ],
              permission: 'Project-Read',
              allowAddPermission: 'Project-Create',
              allowUploadPermission: 'Project-Upload',
              allowEditPermission: 'Project-Update',
              allowDeletePermission: 'Project-Delete',
              allowPrintPermission: 'Project-Print'
            }
          },
          {
            path: 'documents/:id?',
            name: 'project-documents',
            props: true,
            component: () => import('@/views/Projects/Documents.vue'),
            meta: {
              isAllowAll: false,
              role: [
                Role.SystemAdmin,
                Role.SuperAdmin,
                Role.CustomerAdmin,
                Role.ClientUser,
                Role.Approver,
                Role.ClientSuperUser
              ],
              allowAdd: [
                Role.SystemAdmin,
                Role.SuperAdmin,
                Role.ClientSuperUser
              ],
              allowEdit: [
                Role.SystemAdmin,
                Role.SuperAdmin,
                Role.ClientSuperUser
              ],
              allowDelete: [
                Role.SystemAdmin,
                Role.SuperAdmin,
                Role.ClientSuperUser
              ],
              permission: 'Project-Read',
              allowAddPermission: 'Project-Create',
              allowEditPermission: 'Project-Update',
              allowUploadPermission: 'Project-Upload',
              allowDeletePermission: 'Project-Delete'
            }
          }
        ]
      },
      {
        path: 'dataRequests',
        name: 'baseDataRequests',
        component: BaseParent,
        children: [
          {
            path: '',
            name: 'dataRequests',
            component: () => import('@/views/DataRequests/Index.vue'),
            meta: {
              isAllowAll: false,
              role: [
                Role.SystemAdmin,
                Role.SuperAdmin,
                Role.CustomerAdmin,
                Role.ClientUser,
                Role.Salesman,
                Role.ClientSuperUser,
                Role.Approver
              ],
              allowAdd: [
                Role.SystemAdmin,
                Role.SuperAdmin,
                Role.ClientSuperUser
              ],
              allowEdit: [
                Role.SystemAdmin,
                Role.SuperAdmin,
                Role.ClientSuperUser
              ],
              allowDelete: [
                Role.SystemAdmin,
                Role.SuperAdmin,
                Role.ClientSuperUser
              ],
              permission: 'DataRequest-Read',
              allowUploadPermission: 'DataRequest-Update',
              allowAddPermission: 'DataRequest-Create',
              allowEditPermission: 'DataRequest-Update',
              allowDeletePermission: 'DataRequest-Delete'

            }
          },
          {
            path: 'files/:id?',
            name: 'dataRequest-files',
            props: true,
            component: () => import('@/views/DataRequests/Files.vue'),
            meta: {
              isAllowAll: true,
              role: []
            }
          },
          {
            path: 'files/:id/mapping',
            name: 'dataRequest-mapping',
            props: true,
            component: () => import('@/views/DataRequests/Mapping.vue'),
            meta: {
              isAllowAll: true,
              role: []
            }
          }
        ]
      },
      {
        path: 'ndaDocusignSettings',
        name: 'baseNdaDocusignSettings',
        component: BaseParent,
        children: [
          {
            path: '',
            name: 'adminNdaDocusignSettings',
            component: () =>
              import('@/views/.admin/NdaDocusignSettings/Index.vue'),
            meta: {
              breadcrumbs: [
                { title: 'Dashboard', link: 'adminDashboard' },
                { title: 'Nda Docusign Settings' }
              ]
            }
          },
          {
            path: 'view/:id',
            name: 'adminViewNdaDocusignSetting',
            props: true,
            component: () =>
              import('@/views/.admin/NdaDocusignSettings/View.vue'),
            meta: {
              isAllowAll: false,
              role: [Role.SystemAdmin, Role.SuperAdmin],
              permission: 'Settings-Read'
            }
          }
        ]
      },
      {
        path: 'MySqlConfigurations',
        name: 'baseMySqlConfigurations',
        component: BaseParent,
        children: [
          {
            path: '',
            name: 'adminMySqlConfigurations',
            component: () =>
              import('@/views/.admin/MySQLConfigurations/Index.vue'),
            meta: {
              isAllowAll: false,
              role: [Role.SuperAdmin]
            }
          }
        ]
      },
      {
        path: 'pipedriveFields',
        name: 'basePipedriveFields',
        component: BaseParent,
        children: [
          {
            path: '',
            name: 'adminPipedriveFields',
            component: () =>
              import('@/views/.admin/PipedriveFields/Index.vue')
          }
        ]
      },
      {
        path: 'tools',
        name: 'tools',
        component: BaseParent,
        redirect: '/tools/endole',
        children: [
          {
            path: 'endole',
            name: 'endole',
            component: () =>
              import('@/views/Tools/Endole/Index.vue'),
            meta: {
              isAllowAll: false,
              permission: 'Endole-Read'
            }
          },
          {
            path: 'scrapEndoleMapping',
            name: 'scrapEndoleMapping',
            component: () =>
              import('@/views/Tools/ScrapEndoleMapping/Index.vue'),
            meta: {
              isAllowAll: false,
              permission: 'ScrapEndoleMapping-Read'
            }
          },
          {
            path: 'scrapEndole',
            name: 'scrapEndole',
            component: () =>
              import('@/views/Tools/ScrapEndole/Index.vue'),
            meta: {
              isAllowAll: false,
              permission: 'Endole-Read'
            }
          }
        ]
      },
      {
        name: 'helplinks',
        path: 'helplinks',
        component: () => import('@/views/HelpLinks/Index.vue'),
        meta: {
          isAllowAll: false,
          role: [Role.SystemAdmin, Role.SuperAdmin],
          permission: 'Settings-Read'
        }
      },
      {
        name: 'helpsections',
        path: 'helpsections',
        component: () => import('@/views/HelpSections/Index.vue'),
        meta: {
          isAllowAll: false,
          role: [Role.SystemAdmin, Role.SuperAdmin],
          permission: 'Settings-Read'
        }
      },
      {
        name: 'helpArea',
        path: 'helpArea',
        component: () => import('@/views/Helps/Index.vue'),
        meta: {
          isAllowAll: true,
          role: []
        }
      },
      {
        path: 'ecosystemImportLogs',
        name: 'baseEcosystemImportLogs',
        component: BaseParent,
        children: [
          {
            path: '',
            name: 'ecosystemImportLogs',
            component: () => import('@/views/EcosystemImportLogs/Index.vue'),
            meta: {
              isAllowAll: false,
              role: [Role.SystemAdmin, Role.SuperAdmin],
              permission: 'Settings-Read'
            }
          },
          {
            path: ':id/mapping',
            name: 'ecosystemImportLog-mapping',
            props: true,
            component: () => import('@/views/EcosystemImportLogs/Mapping.vue'),
            meta: {
              isAllowAll: false,
              role: [Role.SystemAdmin, Role.SuperAdmin],
              permission: 'Settings-Read'
            }
          },
          {
            path: 'OrganisationDataRaws/:id?',
            name: 'ecosystemImport-organisation',
            props: true,
            component: () =>
              import('@/views/EcosystemImportLogs/OrganisationDataRaws.vue'),
            meta: {
              isAllowAll: false,
              role: [Role.SystemAdmin, Role.SuperAdmin],
              permission: 'Settings-Read'
            }
          }
        ]
      },
      {
        path: 'ecosystemMasters',
        name: 'baseEcosystemMasters',
        component: BaseParent,
        children: [
          {
            path: '',
            name: 'ecosystemMasters',
            redirect: '/ecosystemMasters/organisationSize',
            meta: {
              isAllowAll: false,
              role: [Role.SystemAdmin, Role.SuperAdmin],
              permission: 'Settings-Read'
            }
          },
          {
            path: 'organisationSize',
            name: 'ecosystemMasters-organisationSize',
            component: () =>
              import('@/views/EcosystemMasters/OrganisationSizes/Index.vue'),
            meta: {
              isAllowAll: false,
              role: [Role.SystemAdmin, Role.SuperAdmin],
              permission: 'Settings-Read'
            }
          },
          {
            path: 'organisationInterest',
            name: 'ecosystemMasters-organisationInterest',
            component: () =>
              import(
                '@/views/EcosystemMasters/OrganisationInterests/Index.vue'
              ),
            meta: {
              isAllowAll: false,
              role: [Role.SystemAdmin, Role.SuperAdmin],
              permission: 'Settings-Read'
            }
          },
          {
            path: 'organisationOperatingArea',
            name: 'ecosystemMasters-organisationOperatingArea',
            component: () =>
              import(
                '@/views/EcosystemMasters/OrganisationOperatingAreas/Index.vue'
              ),
            meta: {
              isAllowAll: false,
              role: [Role.SystemAdmin, Role.SuperAdmin],
              permission: 'Settings-Read'
            }
          },
          {
            path: 'organisationSalesChannel',
            name: 'ecosystemMasters-organisationSalesChannel',
            component: () =>
              import(
                '@/views/EcosystemMasters/OrganisationSalesChannels/Index.vue'
              ),
            meta: {
              isAllowAll: false,
              role: [Role.SystemAdmin, Role.SuperAdmin],
              permission: 'Settings-Read'
            }
          },
          {
            path: 'organisationSicCode',
            name: 'ecosystemMasters-organisationSicCode',
            component: () =>
              import('@/views/EcosystemMasters/OrganisationSicCodes/Index.vue'),
            meta: {
              isAllowAll: false,
              role: [Role.SystemAdmin, Role.SuperAdmin],
              permission: 'Settings-Read'
            }
          },
          {
            path: 'organisationSicCodeSection',
            name: 'ecosystemMasters-organisationSicCodeSection',
            component: () =>
              import(
                '@/views/EcosystemMasters/OrganisationSicCodeSections/Index.vue'
              ),
            meta: {
              isAllowAll: false,
              role: [Role.SystemAdmin, Role.SuperAdmin],
              permission: 'Settings-Read'
            }
          },
          {
            path: 'originSourceType',
            name: 'ecosystemMasters-originSourceType',
            component: () =>
              import('@/views/EcosystemMasters/OriginSourceTypes/Index.vue'),
            meta: {
              isAllowAll: false,
              role: [Role.SystemAdmin, Role.SuperAdmin],
              permission: 'Settings-Read'
            }
          },
          {
            path: 'originSourceDetail',
            name: 'ecosystemMasters-originSourceDetail',
            component: () =>
              import('@/views/EcosystemMasters/OriginSourceDetails/Index.vue'),
            meta: {
              isAllowAll: false,
              role: [Role.SystemAdmin, Role.SuperAdmin],
              permission: 'Settings-Read'
            }
          }
        ]
      },
      {
        name: 'typeformNdaRequest',
        path: 'typeformNdaRequest',
        component: () => import('@/views/TypeformNdaRequest/Index.vue'),
        meta: {
          isAllowAll: false,
          role: [Role.SystemAdmin, Role.SuperAdmin],
          permission: 'Settings-Read'
        }
      },
      {
        name: 'checkAppSettings',
        path: 'checkAppSettings',
        component: () => import('@/views/CheckAppSettings/Index.vue'),
        meta: {
          isAllowAll: false,
          role: [Role.SuperAdmin],
          permission: 'Settings-Read'
        }
      },
      {
        name: 'dataRequestTemplates',
        path: 'dataRequestTemplates',
        component: () => import('@/views/DataRequestTemplates/Index.vue'),
        meta: {
          isAllowAll: false,
          permission: 'DataRequestTemplate-Read',
          allowAddPermission: 'DataRequest-Create',
          allowEditPermission: 'DataRequest-Update',
          allowDeletePermission: 'DataRequest-Delete'
        }
      },
      {
        path: 'organisations/:id?',
        name: 'organisation-documents',
        props: true,
        component: () => import('@/views/Organisations/Documents.vue'),
        meta: {
          isAllowAll: false,
          role: [
            Role.SystemAdmin,
            Role.SuperAdmin,
            Role.CustomerAdmin,
            Role.ClientUser,
            Role.Approver,
            Role.ClientSuperUser
          ],
          allowAdd: [
            Role.SystemAdmin,
            Role.SuperAdmin,
            Role.ClientSuperUser
          ],
          allowEdit: [
            Role.SystemAdmin,
            Role.SuperAdmin,
            Role.ClientSuperUser
          ],
          allowDelete: [
            Role.SystemAdmin,
            Role.SuperAdmin,
            Role.ClientSuperUser
          ],
          permission: 'Organisation-Read',
          allowAddPermission: 'Organisation-Create',
          allowEditPermission: 'Organisation-Update',
          allowUploadPermission: 'Organisation-Upload',
          allowDeletePermission: 'Organisation-Delete'
        }
      }
    ]
  },
  {
    name: 'adminLogin',
    path: '/admin/login',
    component: Login,
    meta: {
      isAllowAll: true,
      role: []
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 }
  }
})

export default router
